import * as api from '@apis/common';
import BagIcon from '@assets/images/app/event_bag.png';
import CalendarIcon from '@assets/images/app/event_calendar.png';
import { ReactComponent as DefaultLogo } from '@assets/images/app/event_logo.svg';
import { ReactComponent as QuotesIcon } from '@assets/images/app/event_review_quotes.svg';
import { ReactComponent as StarIcon } from '@assets/images/app/event_review_star.svg';
import MuseumIcon from '@assets/images/app/event_museum.png';
import PeopelIcon from '@assets/images/app/event_people.png';
import EventSection1Image from '@assets/images/app/event_section_1.png';
import EventSection2Image from '@assets/images/app/event_section_2.png';
import EventSection3Image from '@assets/images/app/event_section_3.png';
import { HomeIcon } from '@components/app/common/Icons';
import { AppLoader } from '@components/Loader/AppLoader';
import { useAppNavigation } from '@hooks/appNavigation';
import { useQuery } from '@tanstack/react-query';
import { getNativeAppInfoFromUserAgent, share } from '@utils/common';
import { cdnImageUrl } from '@utils/image';
import { APP_BASE_PATH, APP_STORE_URL } from '@variables';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import * as AppLayout from './AppLayout';
import ImageSlider from '@components/ImageSlider/ImageSlider';
import LinesEllipsis from 'react-lines-ellipsis';
import SvgInline from '@components/common/SvgInline';
import { isMobile } from 'react-device-detect';

const DEFAULT_SAMPLE_IMAGES = [
  '/uploads/event/202410/890ec89e27e34536b574c054a943aabc.webp',
  '/uploads/event/202410/af11b2c5a74748fc922b0b6131440ab8.webp',
  '/uploads/event/202410/03c33b79e9e040e49610edb096d22cbf.webp',
  '/uploads/event/202410/76b581d5e10c441b963aa82d486a7772.webp',
  '/uploads/event/202410/fe2ed7a93ec046bf85ea61b91097fe31.webp',
  '/uploads/event/202410/5f1892bc31054b36a2bab00ca3e6673d.webp',
  '/uploads/event/202410/168ed757dba44748ad9a37986bd8a776.webp',
  '/uploads/event/202410/b504dbb01bcc4ca69700d1bd46c2e2f5.webp',
  '/uploads/event/202410/3b97bf99b29846a28f0be89e03fdf4be.webp',
];

export const EventsPage: React.FC = () => {
  const params = useParams();
  const alias = params.eventAlias;
  const appNavigation = useAppNavigation();
  const [searchParams] = useSearchParams();
  const rewardCode = searchParams.get('rc') || undefined;
  const [badgeImageTop, setBadgeImageTop] = useState<number>(0);
  const { isNativeApp } = getNativeAppInfoFromUserAgent();

  const reviewsQuery = useQuery({
    queryKey: ['best-reviews'],
    queryFn: () => api.getBestReviews(),
  });

  const eventsQuery = useQuery({
    queryKey: [`events`],
    queryFn: () => api.getEvent(alias!),
    enabled: !!alias && alias !== 'invite_friends',
  });

  useEffect(() => {
    document.title = '아이고고 | 이벤트';
    return () => {
      document.title = '아이고고 - 아이를 위한 모든 경험';
    };
  }, []);

  let eventData = eventsQuery.data?.data;

  if (alias === 'invite_friends' && rewardCode) {
    eventData = {
      buttonLabel: '최대 5천원 할인받고 시작하기',
      buttonUrl: `${APP_BASE_PATH}join/referral?rc=${rewardCode}`,
      sampleImages: [],
    };
  }

  const title = eventData?.title || `앱 하나로 만나는\n세상의 모든 체험학습`;
  const subtitle = eventData?.description || `유아부터 초등까지, 서울부터 제주까지\n다양한 경험을 찾는다면, 아이고고`;
  const sampleImages =
    eventData?.sampleImages && eventData?.sampleImages.length > 0 ? eventData?.sampleImages : DEFAULT_SAMPLE_IMAGES;
  const theme = eventData?.theme || 'default';
  const badgeTop = theme === 'white' ? 90 : 160;
  return (
    <AppLayout.Layout>
      <AppLayout.Header
        leftButton={
          <div style={{ marginLeft: '20px' }}>
            <button
              className="home-button"
              onClick={() => {
                appNavigation.to(`${APP_BASE_PATH}`);
              }}>
              <HomeIcon color={'#242424'} style={{ filter: 'none' }} />
            </button>
          </div>
        }
        title={'이벤트'}
        rightButton={<div></div>}></AppLayout.Header>
      <AppLayout.Contents>
        {eventsQuery.isLoading && <AppLoader />}
        {(alias !== 'invite_friends' || !rewardCode || eventsQuery.isSuccess) && !eventData && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            이벤트가 종료되었거나 존재하지 않습니다.
          </div>
        )}
        {eventData && (
          <OverlayScrollbarsComponent
            defer
            options={{
              scrollbars: { autoHide: 'scroll' },
              overflow: {
                x: 'hidden',
              },
            }}
            style={{
              backgroundColor: '#ffffff',
              marginTop: 0,
              position: 'relative',
              overflow: 'auto',
              height: '100%',
              width: '100%',
            }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ position: 'relative', zIndex: 1 }}>
                {theme === 'default' && (
                  <img src={EventSection1Image} alt="section_1" style={{ width: '100%', objectFit: 'cover' }} />
                )}
                {theme === 'white' && <div style={{ height: '574px' }}></div>}
                {theme === 'default' &&
                  (eventData?.logoUrl ? (
                    eventData.logoUrl.endsWith('.svg') ? (
                      <SvgInline
                        url={cdnImageUrl(eventData?.logoUrl)!}
                        style={{ position: 'absolute', top: 24, left: 20, height: '22px' }}
                      />
                    ) : (
                      <img
                        src={cdnImageUrl(eventData?.logoUrl)}
                        style={{ position: 'absolute', top: 24, left: 20, height: '22px' }}
                        alt=""
                      />
                    )
                  ) : (
                    <DefaultLogo style={{ position: 'absolute', top: 24, left: 20, height: '22px' }}></DefaultLogo>
                  ))}
                {eventData?.badgeUrl &&
                  (eventData.badgeUrl.endsWith('.svg') ? (
                    <SvgInline
                      style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        textAlign: 'center',
                        top: badgeImageTop,
                        marginInline: 'auto',
                        opacity: badgeImageTop === 0 ? 0 : 1,
                      }}
                      onLoad={(size) => {
                        setBadgeImageTop(badgeTop - 8 - size.height);
                      }}
                      url={cdnImageUrl(eventData?.badgeUrl)!}
                    />
                  ) : (
                    <img
                      src={cdnImageUrl(eventData?.badgeUrl)}
                      style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: badgeImageTop,
                        marginInline: 'auto',
                        opacity: badgeImageTop === 0 ? 0 : 1,
                      }}
                      alt=""
                      onLoad={(e: any) => {
                        const height = e.target.getBoundingClientRect().height;
                        setBadgeImageTop(badgeTop - 8 - height);
                      }}
                    />
                  ))}
                {eventData?.badgeText && (
                  <div
                    style={{
                      borderRadius: '32px',
                      border: theme === 'white' ? '1px solid #242424' : '1px solid #FFFFFF',
                      color: theme === 'white' ? '#242424' : '#FFFFFF',
                      textAlign: 'center',
                      fontFamily: '"NanumSquare Neo OTF"',
                      fontSize: '13px',
                      fontStyle: 'normal',
                      fontWeight: 900,
                      position: 'absolute',
                      top: badgeTop,
                      left: 0,
                      right: 0,
                      width: 'fit-content',
                      height: 'fit-content',
                      margin: 'auto',
                      padding: '6px 16px',
                    }}>
                    {eventData?.badgeText}
                  </div>
                )}
                <div
                  style={{
                    position: 'absolute',
                    top: badgeTop + 50,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                  {theme === 'white' && eventData?.mainImageUrl && (
                    <img
                      src={cdnImageUrl(eventData?.mainImageUrl)}
                      style={{ width: 'calc(100% - 48px)', margin: '24px', marginTop: 0 }}
                      alt="main"
                    />
                  )}
                  <div
                    style={{
                      color: theme === 'white' ? '#242424' : '#FFFFFF',
                      textAlign: 'center',
                      fontFamily: '"NanumSquare Neo"',
                      fontSize: '30px',
                      fontStyle: 'normal',
                      fontWeight: 800,
                      lineHeight: '140%',
                      letterSpacing: '-0.3px',
                      whiteSpace: 'pre-wrap',
                    }}>
                    {title}
                  </div>
                  <div
                    style={{
                      color: theme === 'white' ? '#424242' : 'rgba(255, 255, 255, 0.80)',
                      textAlign: 'center',
                      fontFamily: '"NanumSquare Neo"',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: '170%',
                      marginTop: '12px',
                      whiteSpace: 'pre-wrap',
                    }}>
                    {subtitle}
                  </div>
                  {eventData.buttonLabel && (
                    <button
                      className="link-button"
                      style={{
                        width: '250px',
                        marginTop: '24px',
                        height: '56px',
                        borderRadius: '8px',
                        background: 'var(--Primary-95, #FF3D8F)',
                        color: 'var(--White-100, var(--naver-text, #FFF))',
                        textAlign: 'center',
                        fontFamily: 'Pretendard',
                        fontSize: '17px',
                        fontStyle: 'normal',
                        fontWeight: 800,
                      }}
                      onClick={() => {
                        window.location.href = eventData.buttonUrl;
                      }}>
                      {eventData.buttonLabel}
                    </button>
                  )}
                </div>
              </div>
              <div style={{ height: '52px', position: 'relative', background: 'var(--Primary-10, #FFF0F6)' }}>
                <div
                  style={{
                    zIndex: 0,
                    position: 'absolute',
                    top: -188,
                    left: '-10%',
                    width: '120%',
                    height: '240px',
                    borderRadius: '100%',
                    background: theme === 'white' ? '#ffffff' : 'var(--Neutral-100, #242424)',
                  }}></div>
              </div>
              <div style={{ marginTop: '-52px' }}>
                <img src={EventSection2Image} alt="event section 2" style={{ width: '100%', display: 'block' }} />
              </div>
              <div style={{ background: 'var(--Primary-10, #FFF0F6)', padding: '60px 16px' }}>
                <div
                  style={{
                    color: 'var(--Primary-95, #FF3D8F)',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                  }}>
                  아이고고를 소개합니다
                </div>
                <div
                  style={{
                    color: 'var(--Neutral-100, #242424)',
                    textAlign: 'center',
                    fontFamily: '"NanumSquare Neo"',
                    fontSize: '26px',
                    fontStyle: 'normal',
                    fontWeight: 900,
                    lineHeight: '140%',
                    letterSpacing: '-0.26px',
                    whiteSpace: 'pre-wrap',
                    marginTop: '12px',
                  }}>
                  {'NO.1 키즈클래스\n대표앱'}
                </div>
                <div style={{ marginTop: '28px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <div style={{ display: 'flex', padding: '28px', background: '#FFF', alignItems: 'center' }}>
                      <img src={CalendarIcon} alt="" style={{ width: '40px', objectFit: 'cover' }} />
                      <div
                        style={{
                          color: 'var(--Neutral-100, #242424)',
                          fontFamily: 'Pretendard',
                          fontSize: '18px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          lineHeight: '140%',
                          whiteSpace: 'pre-wrap',
                          marginLeft: '44px',
                        }}>
                        {'매주, 매달 새로운\n체험학습'}
                      </div>
                    </div>
                    <div style={{ display: 'flex', padding: '28px', background: '#FFF', alignItems: 'center' }}>
                      <img src={MuseumIcon} alt="" style={{ width: '40px', objectFit: 'cover' }} />
                      <div
                        style={{
                          color: 'var(--Neutral-100, #242424)',
                          fontFamily: 'Pretendard',
                          fontSize: '18px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          lineHeight: '140%',
                          whiteSpace: 'pre-wrap',
                          marginLeft: '44px',
                        }}>
                        {'도슨트, 숲체험부터\n예체능까지'}
                      </div>
                    </div>
                    <div style={{ display: 'flex', padding: '28px', background: '#FFF', alignItems: 'center' }}>
                      <img src={BagIcon} alt="" style={{ width: '40px', objectFit: 'cover' }} />
                      <div
                        style={{
                          color: 'var(--Neutral-100, #242424)',
                          fontFamily: 'Pretendard',
                          fontSize: '18px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          lineHeight: '140%',
                          whiteSpace: 'pre-wrap',
                          marginLeft: '44px',
                        }}>
                        {'혼자서도 재밌게\n팀으로 신나게'}
                      </div>
                    </div>
                    <div style={{ display: 'flex', padding: '28px', background: '#FFF', alignItems: 'center' }}>
                      <img src={PeopelIcon} alt="" style={{ width: '40px', objectFit: 'cover' }} />
                      <div
                        style={{
                          color: 'var(--Neutral-100, #242424)',
                          fontFamily: 'Pretendard',
                          fontSize: '18px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          lineHeight: '140%',
                          whiteSpace: 'pre-wrap',
                          marginLeft: '44px',
                        }}>
                        {'부모분리 수업 부터\n가족체험 까지'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {eventData.imageUrl && (
                <div style={{ position: 'relative' }}>
                  <img
                    src={cdnImageUrl(eventData.imageUrl)}
                    alt="이벤트 이미지"
                    style={{ objectFit: 'cover', width: '100%', display: 'block' }}
                  />
                </div>
              )}
              {rewardCode && (
                <div style={{ position: 'relative' }}>
                  <img
                    src={EventSection3Image}
                    alt="이벤트 이미지"
                    style={{ objectFit: 'cover', width: '100%', display: 'block' }}
                  />
                </div>
              )}
              <div
                style={{
                  background: 'var(--Neutral-100, #242424)',
                  padding: '60px 0px 106px 0px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <div
                  style={{
                    color: 'var(--Primary-80, #FF64A5)',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                  }}>
                  생생한 수업 현장
                </div>
                <div
                  style={{
                    color: 'var(--White-100, var(--naver-text, #FFF))',
                    textAlign: 'center',
                    fontFamily: '"NanumSquare Neo"',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 800,
                    lineHeight: '140%',
                    letterSpacing: '-0.2px',
                    marginTop: '4px',
                  }}>
                  아이고고 클래스 앨범
                </div>

                <div style={{ marginTop: '28px' }}>
                  <ImageSlider
                    width={240}
                    height={320}
                    images={sampleImages.map((imageUrl: string, index: number) => {
                      return { id: index, path: cdnImageUrl(imageUrl) };
                    })}></ImageSlider>
                </div>
              </div>
              <div
                style={{
                  background: 'var(--Primary-20, #FFD8E9)',
                  padding: '60px 8px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <div
                  style={{
                    color: 'var(--Primary-95, #FF3D8F)',
                    textAlign: 'center',
                    fontFamily: 'Pretendard',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                  }}>
                  부모님들의 아이고고
                </div>
                <div
                  style={{
                    color: 'var(--Neutral-100, #242424)',
                    textAlign: 'center',
                    fontFamily: '"NanumSquare Neo"',
                    fontSize: '26px',
                    fontStyle: 'normal',
                    fontWeight: 900,
                    lineHeight: '140%',
                    letterSpacing: '-0.26px',
                    whiteSpace: 'pre-wrap',
                    marginTop: '12px',
                  }}>
                  {'진심 가득\n백퍼센트 찐 후기'}
                </div>
                <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap', marginTop: '28px', padding: '0 16px' }}>
                  {(reviewsQuery.data?.data || []).map((review, index) => {
                    return (
                      <div
                        key={`event_review_${index}`}
                        style={{
                          borderRadius: '16px',
                          background: 'var(--naver-text, #FFF)',
                          flex: '0 0 auto',
                          width: 'calc(50% - 4px)',
                          overflow: 'hidden',
                          padding: '20px',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '16px',
                        }}>
                        <QuotesIcon />
                        <div
                          style={{
                            color: 'var(--Neutral-90, #424242)',
                            fontFamily: '"NanumSquare Neo"',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 'medium',
                            lineHeight: '160%',
                          }}>
                          <LinesEllipsis
                            text={review.text || review.content}
                            maxLine={8}
                            ellipsis="..."
                            trimRight></LinesEllipsis>
                        </div>
                        <div style={{ marginTop: 'auto' }}>
                          <StarIcon />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    color: 'var(--Neutral-100, #242424)',
                    textAlign: 'center',
                    fontFamily: '"NanumSquare Neo"',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 800,
                    lineHeight: '140%',
                    letterSpacing: '-0.2px',
                    whiteSpace: 'pre-wrap',
                    marginTop: '60px',
                  }}>
                  {'앱 하나로 만나는\n세상의 모든 체험학습'}
                </div>
                <div
                  style={{
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '16px',
                  }}>
                  <button
                    className="link-button"
                    style={{
                      borderRadius: '8px',
                      background: 'var(--Primary-95, #FF3D8F)',
                      color: 'var(--White-100, var(--naver-text, #FFF))',
                      textAlign: 'center',
                      fontFamily: 'Pretendard',
                      fontSize: '17px',
                      fontStyle: 'normal',
                      fontWeight: 800,
                      padding: '16px 28px',
                    }}
                    onClick={() => {
                      window.location.href = eventData.buttonUrl;
                    }}>
                    {eventData?.nextButtonLabel || '아이고고 시작하기'}
                  </button>
                  {!isNativeApp && isMobile && (
                    <button
                      className="link-button"
                      style={{
                        borderRadius: '8px',
                        background: 'var(--Primary-95, #FF3D8F)',
                        color: 'var(--White-100, var(--naver-text, #FFF))',
                        textAlign: 'center',
                        fontFamily: 'Pretendard',
                        fontSize: '17px',
                        fontStyle: 'normal',
                        fontWeight: 800,

                        padding: '16px 28px',
                      }}
                      onClick={() => {
                        window.open(APP_STORE_URL, '_blank');
                      }}>
                      아이고고 앱 다운로드
                    </button>
                  )}
                </div>
              </div>
              {rewardCode && (
                <div
                  style={{
                    background: 'var(--Neutral-100, #242424)',
                    padding: '40px 8px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                  <div
                    style={{
                      color: 'var(--Primary-80, #FF64A5)',
                      textAlign: 'center',
                      fontFamily: 'Pretendard',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                    }}>
                    혼자 알기 아까운 아이고고
                  </div>
                  <div
                    style={{
                      marginTop: '12px',
                      color: 'var(--White-100, var(--naver-text, #FFF))',
                      textAlign: 'center',
                      fontFamily: '"NanumSquare Neo"',
                      fontSize: '26px',
                      fontStyle: 'normal',
                      fontWeight: 900,
                      lineHeight: '140%',
                      letterSpacing: '-0.26px',
                    }}>
                    {'친구들에게\n아이고고 알려주기'}
                  </div>
                  <button
                    className="link-button"
                    style={{
                      borderRadius: '8px',
                      background: 'var(--Primary-95, #FF3D8F)',
                      color: 'var(--White-100, var(--naver-text, #FFF))',
                      textAlign: 'center',
                      fontFamily: 'Pretendard',
                      fontSize: '17px',
                      fontStyle: 'normal',
                      fontWeight: 800,
                      marginTop: '20px',
                      padding: '16px 28px',
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      share({ type: 'reward', id: rewardCode });
                    }}>
                    <span>공유하기</span>
                  </button>
                </div>
              )}
            </div>
          </OverlayScrollbarsComponent>
        )}
      </AppLayout.Contents>
      <AppLayout.Footer></AppLayout.Footer>
    </AppLayout.Layout>
  );
};

export default EventsPage;
