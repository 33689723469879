export enum Roles {
  ADMIN = 'admin',
  SUBADMIN = 'subadmin',
  SALEADMIN = 'saleadmin',
  PARENT = 'parent',
  TUTOR = 'teacher',
  SELLER = 'seller',
  PARTNER = 'partner',
  EDITOR = 'editor',
}

export enum TutorContractType {
  CONTRACT_TYPE_BUSINESS = 'business',
  CONTRACT_TYPE_FREELANCER = 'freelancer',
  CONTRACT_TYPE_STUDENT = 'student',
}

export const ROLES_TEXT: { [key: string]: string } = {
  admin: '어드민',
  subadmin: '서브어드민',
  saleadmin: '판매어드민',
  parent: '부모',
  teacher: '튜터',
  seller: '셀러',
  partner: '파트너',
  editor: '에디터',
};
