import React from 'react';

export const PointBenefits: React.FC<{
  totalFee: number;
}> = ({ totalFee }) => {
  const purchasePoint = Math.floor(totalFee * 0.005);
  const photoReviewPoint = Math.floor(totalFee * 0.03);
  const bestReviewPoint = Math.floor(totalFee * 0.05);

  return (
    <div
      style={{
        marginTop: '24px',
        paddingLeft: '24px',
        paddingRight: '24px',
        marginBottom: '24px',
      }}>
      <div
        style={{
          fontSize: '18px',
          lineHeight: '21px',
          color: '#242424',
          fontWeight: 'bold',
        }}>
        포인트 혜택
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: '17px',
          justifyContent: 'space-between',
          marginBottom: '12px',
        }}>
        <div
          style={{
            fontSize: '14px',
            lineHeight: '17px',
            color: '#66686b',
          }}>
          구매적립
        </div>
        <div
          style={{
            fontSize: '16px',
            lineHeight: '20px',
            color: '#242424',
          }}>
          {`${purchasePoint?.toLocaleString()}`}원
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 0,
          justifyContent: 'space-between',
          marginBottom: '12px',
        }}>
        <div
          style={{
            fontSize: '14px',
            lineHeight: '17px',
            color: '#66686b',
          }}>
          리뷰적립
        </div>
        <div
          style={{
            fontSize: '16px',
            lineHeight: '20px',
            color: '#242424',
          }}>
          {`최대 ${photoReviewPoint?.toLocaleString()}원`}
        </div>
      </div>
      <div
        style={{
          color: '#FF3D8F',
          textAlign: 'center',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          height: '40px',
          borderRadius: '4px',
          background: 'var(--Primary-5, #FFF5F9)',
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          marginTop: '12px',
          padding: '10px 12px',
        }}>
        <div
          style={{
            color: '#FF3D8F',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            flexShrink: 0,
          }}>
          베스트 후기 선정 시
        </div>
        <div style={{ display: 'flex', alignItems: 'flex-start', flexGrow: 1 }}>
          <div style={{ width: '100%', height: '50%', borderBottom: '1px dashed #FFC5DD' }}></div>
        </div>
        <div
          style={{
            color: 'var(--Primary-95, #FF3D8F)',
            textAlign: 'right',
            fontFamily: 'Pretendard',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: 700,
            flexShrink: 0,
          }}>
          {bestReviewPoint.toLocaleString()}원 추가 적립 🎉
        </div>
      </div>
    </div>
  );
};

export default PointBenefits;
