import React, { useEffect } from 'react';
import { Button } from 'antd';
import './NotFound.scss';
import { useAppNavigation } from '@hooks/appNavigation';

export const NotFoundPage: React.FC = () => {
  const appNavigation = useAppNavigation();

  useEffect(() => {
    document.title = '404 | 아이고고';
    return () => {
      document.title = '아이고고 - 아이를 위한 모든 경험';
    };
  }, []);

  return (
    <div className="not-found">
      <div className="background-overlay"></div>
      <div className="content">
        <div className="error">ERROR</div>
        <div className="code">404</div>
        <div className="title">요청한 페이지가 없습니다.</div>
        <div className="description">
          <p>방문하시려는 페이지의 주소가 잘못 입력되었거나,</p>
          <p>페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.</p>
          <p>입력하신 주소가 정확한지 다시 한번 확인해 주세요.</p>
        </div>
        <Button
          className="btn-yellow"
          onClick={() => {
            appNavigation.back();
          }}>
          뒤로 돌아가기
        </Button>
      </div>
    </div>
  );
};
