import { ReactComponent as DeleteImageIcon } from '@assets/images/editor/x-circle.svg';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  horizontalListSortingStrategy,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { cdnImageUrl } from '@utils/image';
import React from 'react';

interface SortableImageProps {
  onDeleteDetailImage?: (event: any) => void;
  value?: any;
  key: any;
  id: any;
  imageUrl: string;
  style?: any;
}

const SortableImage: React.FC<SortableImageProps> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'pointer',
  };

  const { onDeleteDetailImage, imageUrl } = props;
  const onClickRemove = (event: any) => {
    event.preventDefault();
    onDeleteDetailImage && onDeleteDetailImage(event);
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div
        style={{
          width: '56px',
          height: '56px',
          border: '1px solid var(--Neutral-40, #D5D8DD)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}>
        <button
          className="link-button"
          style={{
            width: '20px',
            height: '20px',
            position: 'absolute',
            top: '-8px',
            right: '-8px',
            zIndex: 1,
            borderRadius: '50%',
            border: '1px solid #FFF',
            background: 'rgba(0, 0, 0, 0.70)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={onClickRemove}>
          <DeleteImageIcon />
        </button>
        <img
          src={cdnImageUrl(imageUrl)}
          style={{
            width: '56px',
            height: '56px',
            objectFit: 'cover',
            backgroundColor: '#000',
          }}
          alt=""
        />
      </div>
    </div>
  );
};

export interface SortableImagesContainerProps {
  images?: string[];
  onDragEnd?: (event: DragEndEvent) => void;
  onDeleteImage?: (index: number) => void;
}

export const SortableImagesContainer: React.FC<SortableImagesContainerProps> = (props) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={props.onDragEnd}>
      <SortableContext items={(props.images || []).map((item) => item)} strategy={horizontalListSortingStrategy}>
        <div style={{ display: 'flex', gap: '12px', overflow: 'visible', maxWidth: '600px', flexWrap: 'wrap' }}>
          {(props.images || []).map((imageUrl: string, index: number) => {
            return (
              <div key={`${imageUrl}-${index}`} style={{ position: 'relative' }}>
                <SortableImage
                  key={`image-${imageUrl}-${index}`}
                  id={imageUrl}
                  onDeleteDetailImage={() => {
                    props.onDeleteImage && props.onDeleteImage(index);
                  }}
                  imageUrl={imageUrl}
                />
              </div>
            );
          })}
        </div>
      </SortableContext>
    </DndContext>
  );
};
