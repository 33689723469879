import React, { CSSProperties } from 'react';
import { ReactComponent as ChevronDownIcon } from '@assets/images/editor/chevron-down.svg';

export interface LoungeEditorTopButtonProps {
  style?: CSSProperties;
  children?: any;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const LoungeEditorTopButton: React.FC<LoungeEditorTopButtonProps> = (props) => {
  return (
    <button
      className="link-button"
      style={{ width: '100%', ...props.style }}
      onClick={(e) => {
        props.onClick?.(e);
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 20px',
          gap: '20px',
        }}>
        <div style={{ minWidth: 0, width: '100%', textAlign: 'left' }}>{props.children}</div>
        <div style={{ flexShrink: 0 }}>
          <ChevronDownIcon />
        </div>
      </div>
    </button>
  );
};

export default LoungeEditorTopButton;
