import CalendarIcon from '@assets/images/app/calendar.png';
import ClockIcon from '@assets/images/app/clock.png';
import React, { useEffect, useRef, useState } from 'react';
import { DatePicker, TimePicker } from 'antd';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import moment from 'moment';
import 'dayjs/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { checkIsDateType } from '@utils/date';

dayjs.extend(weekday);
dayjs.extend(localeData);

export interface DateTimeButtonPickerProps {
  value?: Date;
  minDate?: string; // YYYY-MM-DD
  maxDate?: string; // YYYY-MM-DD
  minTime?: string; // HH:mm
  maxTime?: string; // HH:mm
  onChange?: (value: Date) => void;
}

export const DateTimeButtonPicker: React.FC<DateTimeButtonPickerProps> = (props) => {
  const timeRef = useRef<any>();
  const [dateString, setDateString] = useState<string>();
  const [dateValue, setDateValue] = useState<dayjs.Dayjs | null>();
  const [timeString, setTimeString] = useState<string>();
  const [timeValue, setTimeValue] = useState<dayjs.Dayjs | null>();
  const [date, setDate] = useState<Date>();

  useEffect(() => {
    const dateStr = dateString || moment(date).format('YYYY-MM-DD');
    const newDate = new Date(Date.parse(`${dateStr} ${timeString || props.minTime || '00:00:00'}`));
    if (newDate instanceof Date && checkIsDateType(newDate)) {
      setDate(newDate);
      setDateValue(dayjs(newDate));
      setTimeValue(dayjs(newDate));

      if (dateString || timeString) {
        props.onChange && props.onChange(newDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateString, timeString]);

  useEffect(() => {
    if (props.value && props.value instanceof Date && checkIsDateType(props.value)) {
      setDate(props.value);
      setDateValue(dayjs(props.value));
      setTimeValue(dayjs(props.value));
    }
  }, [props.value]);

  return (
    <div style={{ display: 'flex', marginTop: '8px', gap: '8px' }}>
      <DatePicker
        locale={locale}
        allowClear={false}
        style={{ flex: 1, height: '52px' }}
        format={'YY. MM. DD(dddd)'}
        placeholder={'날짜'}
        suffixIcon={<img src={CalendarIcon} alt=""></img>}
        value={dateValue}
        onChange={(e) => {
          setDate(e?.toDate());
          setDateString(e?.format('YYYY-MM-DD'));
          if (timeRef.current) {
            timeRef.current.showPicker();
          }
        }}
      />
      <TimePicker
        format={'HH:mm'}
        allowClear={false}
        minuteStep={10}
        value={timeValue}
        style={{ flex: 1, height: '52px' }}
        placeholder={'시간'}
        suffixIcon={<img src={ClockIcon} alt=""></img>}
        onChange={(e) => {
          setTimeValue(e as dayjs.Dayjs);
          setTimeString(e?.format('HH:mm:00'));
        }}
        onBlur={(e) => {
          const value = e.target.value;
          setTimeValue(dayjs(value + ':00'));
          setTimeString(value + ':00');
        }}
      />
    </div>
  );
};

export default DateTimeButtonPicker;
