import React from 'react';
import { MOBILE_CONFIRM_MODAL_WIDTH } from '@consts/ui';
import { Modal } from 'antd';
import { useAppUpdateModal } from '@stores/appUpdateModalStore';
import moment from 'moment';
import { goToAppStore } from '@utils/common';

export const AppUpdateModal: React.FC = () => {
  const { show, openData, close } = useAppUpdateModal();
  const getContent = () => {
    let contentText = '';
    if (openData?.type === 'maintenance') {
      contentText = `
        ${openData.description}
        (${moment(openData.startDate).format('MM/DD - HH:mm')} ~ ${moment(openData.endDate).format('MM/DD - HH:mm')})
      `;
    } else if (openData?.type === 'force') {
      contentText = `원활한 앱 사용을 위해, 앱을 최신 버전으로 업데이트 해주세요.`;
    } else if (openData?.type === 'recommend') {
      contentText = `최신 업데이트를 진행해주세요.`;
    }
    return contentText;
  };
  return (
    <Modal
      width={MOBILE_CONFIRM_MODAL_WIDTH}
      centered
      open={show}
      closable={false}
      maskClosable={openData?.type === 'recommend' ? true : false}
      onCancel={() => {
        close();
      }}
      footer={null}>
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '12px',
        }}>
        <div
          style={{
            whiteSpace: 'pre-line',
            color: 'var(--Neutral-100, #242424)',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '24px',
          }}>
          {openData?.type === 'maintenance' ? '서비스 점검' : '앱 업데이트'}
        </div>
        <div
          style={{
            marginTop: '8px',
            marginBottom: '24px',
            color: 'var(--Neutral-80, #66686B)',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            whiteSpace: 'pre-line',
          }}>
          {getContent()}
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {openData?.type === 'recommend' && (
            <div
              style={{ flex: 1 }}
              onClick={() => {
                close();
              }}>
              <div
                style={{
                  height: '48px',
                  backgroundColor: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderStyle: 'solid',
                  borderRadius: '8px',
                  borderWidth: '1px',
                  borderColor: '#dadcdf',
                  marginRight: '8px',
                  cursor: 'pointer',
                }}>
                <div style={{ color: '#808387', fontSize: '16px', fontWeight: 'bold' }}>{'닫기'}</div>
              </div>
            </div>
          )}
          {(openData?.type === 'recommend' || openData?.type === 'force') && (
            <div
              style={{ flex: 1 }}
              onClick={() => {
                goToAppStore();
              }}>
              <div
                style={{
                  height: '48px',
                  backgroundColor: '#FF3D8F',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '8px',
                  cursor: 'pointer',
                }}>
                <div style={{ color: '#FFFFFF', fontSize: '16px', fontWeight: 'bold' }}>{'업데이트'}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AppUpdateModal;
