import React, { useEffect, useState } from 'react';

import * as classApi from '@apis/app-classes';
import * as productsApi from '@apis/app-products';
import * as tutorProductsApi from '@apis/tutor-products';
import { useAppNavigation } from '@hooks/appNavigation';
import { usePayment } from '@hooks/payment';
import { CreateClassInvoiceData } from '@models/invoice';
import { useAuthStore } from '@stores/authStore';
import { ClassInvoiceViewMode, useClassInvoiceModalStore } from '@stores/classInvoiceModalStore';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import AppBottomModal from '../common/AppBottomModal';
import { ClassInvoiceChangeSchedule } from './view/ClassInvoiceChangeSchedules';
import { ClassInvoiceConfirm } from './view/ClassInvoiceConfirm';
import { ClassInvoiceCreate } from './view/ClassInvoiceCreate';
import { ClassInvoicePayment } from './view/ClassInvoicePayment';
import { ClassInvoicePaymentComplete } from './view/ClassInvoicePaymentComplete';
import { ClassInvoiceView } from './view/ClassInvoiceView';
import { APP_BASE_PATH } from '@variables';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';

export const ClassInvoiceModal: React.FC = () => {
  const payment = usePayment();
  const [createData, setCreateData] = useState<CreateClassInvoiceData>();
  const [viewMode, setViewMode] = useState<ClassInvoiceViewMode>('view');
  const { user } = useAuthStore();
  const { show, close, data } = useClassInvoiceModalStore();
  const appNavigation = useAppNavigation();
  const queryClient = useQueryClient();
  const okCancelDialog = useOkCancelDialog();

  const onPopstate = () => {
    payment.clear();
    if (viewMode === 'create_confirm') {
      setViewMode('create');
    } else if (viewMode === 'class_schedule_edit') {
      setViewMode('view');
    } else {
      close();
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', onPopstate);
    return () => {
      window.removeEventListener('popstate', onPopstate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.mode) {
      setViewMode(data?.mode);
    }
  }, [data]);

  const classId = data?.classId;
  const classInvoiceId = data?.classInvoiceId;
  const classInvoiceQuery = useQuery({
    queryKey: [`class-invoice-${classInvoiceId}-${payment.status}`],
    queryFn: () => classApi.getClassInvoice(classId!, classInvoiceId!),
    enabled: !!classInvoiceId && !!classId && show,
  });

  const classItemQuery = useQuery({
    queryKey: [`class-item-${classId}`],
    queryFn: () => productsApi.getProduct(classId!),
    enabled: !!classId,
  });

  const updateClassScheduleMutation = useMutation({
    mutationFn: tutorProductsApi.updateClassSchedule,
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: [`class-invoice-${classInvoiceId}-${payment.status}`] });
    },
  });

  const updateClassSchedulesMutation = useMutation({
    mutationFn: tutorProductsApi.updateClassSchedules,
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: [`class-invoice-${classInvoiceId}-${payment.status}`] });
      setViewMode('view');
    },
  });

  const classInvoice = classInvoiceQuery.data?.data;
  const classProduct = classItemQuery.data?.data;

  let classCardTitle = '수강증';

  const isTutorLogin = user?.id === classInvoice?.tutorId || user?.id === classProduct?.sellerId || false;

  useEffect(() => {
    if (show && classItemQuery && classItemQuery.isSuccess && !classProduct) {
      okCancelDialog.open({
        title: '비공개 클래스',
        content: '비공개 상태의 클래스입니다.',
        type: 'ok',
      });
      close();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onClickReport = () => {
    close();
    const to = isTutorLogin ? `${APP_BASE_PATH}class-report/edit` : `${APP_BASE_PATH}class-report/view`;
    const parentState = {
      userId: user?.id,
      classId: classId,
      classInvoiceId: classInvoiceId,
    };
    const tutorState = {
      userId: classInvoice && (classInvoice.parentId || classInvoice.userId),
      classId: classId,
      classInvoiceId: classInvoiceId,
      tutorName: user?.profile?.nickname,
      children: classInvoice && classInvoice.students,
    };
    appNavigation.to(to, {
      state: isTutorLogin ? tutorState : parentState,
    });
  };

  let modalView = <div></div>;
  if (viewMode === 'create') {
    classCardTitle = '수강증 생성';
    if (data?.classId && data?.parentId && isTutorLogin) {
      modalView = (
        <ClassInvoiceCreate
          classId={data?.classId}
          parentId={data?.parentId}
          classInquiryInfo={data?.classInquiryInfo}
          chatRoomId={data?.chatRoomId}
          onConfirm={(data) => {
            setCreateData(data);
            setViewMode('create_confirm');
          }}
        />
      );
    }
  } else if (viewMode === 'create_confirm') {
    classCardTitle = '수강증 발행';
    modalView = (
      <ClassInvoiceConfirm
        data={createData}
        onCancel={() => {
          setViewMode('create');
        }}
      />
    );
  } else if (viewMode === 'payment') {
    classCardTitle = '결제하기';
    if (classInvoice) {
      modalView = (
        <ClassInvoicePayment
          open={show}
          classInvoice={classInvoice}
          isTutorLogin={isTutorLogin}
          onCancel={() => {
            close();
          }}
          onPaymentCompleted={() => {
            setViewMode('payment_completed');
          }}
        />
      );
    }
  } else if (viewMode === 'payment_completed') {
    classCardTitle = '결제완료';
    modalView = (classInvoice && (
      <ClassInvoicePaymentComplete classInvoice={classInvoice} paymentStatement={payment.paymentStatement} />
    )) || <></>;
  } else if (viewMode === 'class_schedule_edit') {
    modalView = (classInvoice && (
      <ClassInvoiceChangeSchedule
        classInvoice={classInvoice}
        onConfirm={(schedules) => {
          updateClassSchedulesMutation.mutate({ classId: classId!, requestId: classInvoiceId!, schedules });
        }}
      />
    )) || <></>;
  } else {
    classCardTitle = '수강 상세';
    if (classInvoice) {
      modalView = (
        <ClassInvoiceView
          classProduct={classProduct}
          classInvoice={classInvoice}
          isTutorLogin={isTutorLogin}
          onCancel={() => {
            close();
          }}
          onConfirm={() => {
            close();
          }}
          onClickReport={onClickReport}
          onChangeSchedule={(schedule) => {
            updateClassScheduleMutation.mutate(schedule);
          }}
          onClickScheduleEdit={() => {
            setViewMode('class_schedule_edit');
          }}
        />
      );
    }
  }

  return (
    <AppBottomModal
      open={show && !!data}
      title={classCardTitle}
      hideRightNaviButton={true}
      leftNaviButtonIcon={viewMode === 'create_confirm' ? 'back' : 'close'}
      onLeftNaviButtonClick={() => {
        payment.clear();
        if (viewMode === 'create_confirm') {
          setViewMode('create');
        } else if (viewMode === 'class_schedule_edit') {
          setViewMode('view');
        } else {
          close();
        }
      }}>
      {modalView}
    </AppBottomModal>
  );
};
