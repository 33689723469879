import React from 'react';

export interface FileUploadButtonPros {
  style?: any;
  children: Element[] | JSX.Element[] | Element | JSX.Element | any;
  className?: string;
  title?: string;
  disabled?: boolean | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
  multiple?: boolean;
}

const defaultAccepts = [
  'jpg',
  'jpeg',
  'png',
  'tif',
  'tiff',
  'webp',
  'mp4',
  'm4v',
  'avi',
  'asf',
  'wmv',
  'mkv',
  'ts',
  'mpg',
  'mpeg',
  'mov',
  'flv',
  'ogv',
  'mp3',
  'wav',
  'flac',
  'tta',
  'tak',
  'aac',
  'wma',
  'ogg',
  'm4a',
  'pdf',
  'odp',
  'ppt',
  'pptx',
  'key',
  'show',
  'doc',
  'docx',
  'hwp',
  'txt',
  'rtf',
  'xml',
  'wks',
  'wps',
  'xps',
  'md',
  'odf',
  'odt',
  'pages',
  'ods',
  'csv',
  'tsv',
  'xls',
  'xlsx',
  'numbers',
  'cell',
  'psd',
  'ai',
  'sketch',
  'tif',
  'tiff',
  'webp',
  'tga',
  'zip',
  'gz',
  'bz2',
  'rar',
  '7z',
  'lzh',
  'alz',
]
  .map((ext) => {
    return '.' + ext;
  })
  .join(',');

const FileUploadButton: React.FC<FileUploadButtonPros> = (props) => {
  const { style, title, disabled, accept, children, className, onChange, multiple } = props;
  const hiddenFileInput = React.useRef<any>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (onChange) {
      onChange(event);
    }
    hiddenFileInput.current.value = null;
  };
  return (
    <>
      <button title={title} disabled={disabled} className={className} style={style} onClick={handleClick}>
        {children}
      </button>
      <input
        type="file"
        accept={accept || defaultAccepts}
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        multiple={multiple}
      />
    </>
  );
};
export default FileUploadButton;
