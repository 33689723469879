import { PaymentStatement } from '@models/purchase';
import { create } from 'zustand';

export interface LoungeEditorModalData {
  board: string; // review, curation
  category?: string;
  userContentId?: string;
  paymentStatement?: PaymentStatement;
  onReviewChanged?: () => void;
}

interface LoungeEditorModalStore {
  show?: boolean;
  data?: LoungeEditorModalData;
  close: () => boolean;
  open: (data?: LoungeEditorModalData) => void;
}

export const useLoungeEditorModalStore = create<LoungeEditorModalStore>((set, get) => ({
  show: false,
  close: () => {
    const isCloseExecute = !!get().show;
    set({ show: false });
    return isCloseExecute;
  },
  open: (data?: LoungeEditorModalData) => {
    set({ data: data, show: true });
  },
}));
