import { useAppPopup } from '@hooks/appPopup';
import { useAppVersion } from '@hooks/appVersion';
import { getNativeAppInfoFromUserAgent, goToAppStore } from '@utils/common';
import { WEB_VERSION } from '@variables';
import { Button } from 'antd';
import React, { useEffect } from 'react';
import * as AppLayout from '../AppLayout';
import './AccountSettingsPage.scss';

export const AppSettingsPage: React.FC = () => {
  const { isAppUpdateAvailable } = useAppPopup();
  const { isUpdateAvailable } = useAppVersion();
  const { isNativeApp, appVersion } = getNativeAppInfoFromUserAgent();

  useEffect(() => {
    document.title = `아이고고 | 마이페이지 > 앱 설정`;
    return () => {
      document.title = '아이고고 - 아이를 위한 모든 경험';
    };
  }, []);

  return (
    <AppLayout.Layout>
      <AppLayout.Header title="앱 설정"></AppLayout.Header>
      <AppLayout.Contents>
        <div>
          <div>
            <div style={{ backgroundColor: '#ffffff', padding: '16px' }}>
              <div style={{ backgroundColor: '#ffffff', paddingTop: '28px' }}>
                <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#242424' }}>버전</div>
              </div>
              <div
                style={{
                  backgroundColor: '#ffffff',
                  paddingTop: '30px',
                  paddingBottom: '16px',
                }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <div
                    style={{
                      fontSize: '16px',
                      color: '#424242',
                      lineHeight: '24px',
                      marginBottom: '2px',
                      width: '70%',
                      wordWrap: 'break-word',
                    }}>
                    웹 버전 {WEB_VERSION}
                  </div>
                  {isUpdateAvailable && (
                    <Button
                      type="primary"
                      onClick={() => {
                        window.location.reload();
                      }}>
                      새로고침
                    </Button>
                  )}
                </div>
              </div>
              {isNativeApp && (
                <div
                  style={{
                    backgroundColor: '#ffffff',
                    paddingTop: '30px',
                    paddingBottom: '16px',
                    borderTop: '1px solid #eff1f4',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}>
                    <div
                      style={{
                        fontSize: '16px',
                        color: '#424242',
                        lineHeight: '24px',
                        marginBottom: '2px',
                        width: '70%',
                        wordWrap: 'break-word',
                      }}>
                      앱 버전 v{appVersion}
                    </div>
                    {isAppUpdateAvailable && (
                      <Button
                        type="primary"
                        onClick={() => {
                          goToAppStore();
                        }}>
                        업데이트
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </AppLayout.Contents>
      <AppLayout.Footer hide></AppLayout.Footer>
    </AppLayout.Layout>
  );
};

export default AppSettingsPage;
