import { Modal } from 'antd';
import React, { useState } from 'react';
// import { sendSMS } from '../../modules/notifications';
import { isMobile } from 'react-device-detect';
import { useAppInstallModal } from '@stores/appModal';
import PopupCloseButtonImage from '@assets/images/index/web_btn_popup_close.png';
import PopupImage from '@assets/images/index/img-popup.png';

export const AppInstallModal: React.FC = () => {
  const { show, setShow } = useAppInstallModal();
  const [phone, setPhone] = useState<string>();
  const AppInstallModalMobile = () => {
    return (
      <Modal
        open={show}
        className={'appModal'}
        width={'100%'}
        onCancel={() => {
          setShow(false);
        }}
        footer={null}>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: 18,
            position: 'relative',
          }}>
          <img style={{ width: 25, position: 'absolute', right: 0, top: 0 }} src={PopupCloseButtonImage} alt="" />
        </div>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img style={{ width: 150, marginTop: 20 }} src={PopupImage} alt="" />
          <div
            style={{
              color: '#000000',
              marginTop: 32,
              fontSize: 18,
              fontWeight: 'bold',
            }}>
            아이고고, 앱으로 만나보세요
          </div>
          <div style={{ color: '#7b7b7b', marginTop: 5, fontSize: 12 }}>가입 즉시 5,000포인트 적립!</div>
          <div
            style={{
              width: '100%',
              height: 50,
              display: 'flex',
              margin: '20px auto 0px auto',
            }}>
            <input
              className={'phoneStyleMobile'}
              value={phone}
              onChange={(event) => {
                let phoneNumber = event.target.value;
                phoneNumber = phoneNumber.replace(/-/g, '');
                setPhone(phoneNumber);
              }}
              placeholder="휴대폰 번호를 입력하세요."
            />
            <div
              style={{
                width: 100,
                borderTopRightRadius: '12px',
                borderBottomRightRadius: '12px',
                backgroundColor: '#3f94f7',
                cursor: 'pointer',
                color: '#FFFFFF',
                textAlign: 'center',
                justifyContent: 'center',
                fontSize: 15,
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => {
                // sendSMS(phone);
              }}>
              SMS 전송
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const AppInstallModalPC = () => {
    return (
      <Modal
        open={show}
        className={'appModal'}
        width={587}
        onCancel={() => {
          setShow(false);
        }}
        footer={null}
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          top: 0,
        }}>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: 18,
            position: 'relative',
          }}>
          <img
            style={{ width: 32, position: 'absolute', right: 0, top: 0 }}
            src="/assets/images/index/web_btn_popup_close.png"
            alt=""
          />
        </div>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img
            style={{ width: '417px', height: '245px', marginTop: '40px' }}
            src="/assets/images/index/img-popup.png"
            alt=""
          />
          <div
            style={{
              color: '#242424',
              marginTop: 40,
              fontSize: '30px',
              fontWeight: 'bold',
            }}>
            앱에서 더 좋은 선생님을 만나보세요!
          </div>
          <div
            style={{
              color: '#808387',
              marginTop: '12px',
              fontSize: '19px',
              marginBottom: '40px',
            }}>
            선생님과 직접 상담하고 수업을 신청할 수 있어요!
          </div>
          <div
            style={{
              // width: 342+165,
              height: 60,
              display: 'flex',
              margin: '25px auto 40px auto',
            }}>
            <input
              className={'phoneStyle'}
              value={phone}
              onChange={(event) => {
                let phoneNumber = event.target.value;
                phoneNumber = phoneNumber.replace(/-/g, '');
                setPhone(phoneNumber);
              }}
              placeholder="휴대폰 번호를 입력하세요."
            />
            <div
              style={{
                padding: '0 36px',
                borderTopRightRadius: '12px',
                borderBottomRightRadius: '12px',
                backgroundColor: '#FF3D8F',
                cursor: 'pointer',
                color: '#FFFFFF',
                textAlign: 'center',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                fontSize: '19px',
              }}
              onClick={() => {
                // sendSMS(phone);
              }}>
              앱 링크 발송
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  if (isMobile) {
    return AppInstallModalMobile();
  } else {
    return AppInstallModalPC();
  }
};

export default AppInstallModal;
