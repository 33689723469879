import * as feedApi from '@apis/app-feed';
import { ReactComponent as BubbleTail } from '@assets/images/app/bubble-tail-bottom.svg';
import { ReactComponent as DesktopBackground } from '@assets/images/app/desktop-background.svg';
import { ReactComponent as DesktopExtensionAppStoreIcon } from '@assets/images/app/desktop-extension-app-store-icon.svg';
import { ReactComponent as DesktopExtensionInstagramIcon } from '@assets/images/app/desktop-extension-instagram-icon.svg';
import { ReactComponent as DesktopExtensionNaverBlogIcon } from '@assets/images/app/desktop-extension-naver-blog-icon.svg';
import { ReactComponent as DesktopExtensionPlayStoreIcon } from '@assets/images/app/desktop-extension-play-store-icon.svg';
import { ReactComponent as DesktopExtensionSearchIcon } from '@assets/images/app/desktop-extension-search-icon.svg';
import DesktopExtensionTitle from '@assets/images/app/desktop-extension-title.png';
import { ReactComponent as FooterChatOff } from '@assets/images/app/footer-chat-off.svg';
import { ReactComponent as FooterChatOn } from '@assets/images/app/footer-chat-on.svg';
import { ReactComponent as FooterHomeOff } from '@assets/images/app/footer-home-off.svg';
import { ReactComponent as FooterHomeOn } from '@assets/images/app/footer-home-on.svg';
import { ReactComponent as FooterLoungeOff } from '@assets/images/app/footer-lounge-off.svg';
import { ReactComponent as FooterLoungeOn } from '@assets/images/app/footer-lounge-on.svg';
import { ReactComponent as FooterMyPageOff } from '@assets/images/app/footer-my-page-off.svg';
import { ReactComponent as FooterMyPageOn } from '@assets/images/app/footer-my-page-on.svg';
import { ReactComponent as FooterSearchOff } from '@assets/images/app/footer-search-off.svg';
import { ReactComponent as FooterSearchOn } from '@assets/images/app/footer-search-on.svg';
import AppBottomSheet from '@components/app/common/AppBottomSheet';
import { NavBackIcon } from '@components/app/common/Icons';
import { OkCancelDialog } from '@components/app/common/OkCancelDialog';
import AppLoginModal from '@components/app/modal/AppLoginModal';
import AppTermsModal from '@components/app/modal/AppTermsModal';
import AppUpdateModal from '@components/app/modal/AppUpdateModal';
import { ClassInvoiceModal } from '@components/app/modal/ClassInvoiceModal';
import LoungeEditorModal from '@components/LoungeEditor/LoungeEditorModal';
import { HEADER_HEIGHT } from '@consts/ui';
import { useAppMessage } from '@hooks/appMessage';
import { useAppNavigation } from '@hooks/appNavigation';
import { useAppPopup } from '@hooks/appPopup';
import { useAppVersion } from '@hooks/appVersion';
import { DeepLinkActions, useDeeplink } from '@hooks/deeplink';
import { useAppUserSessionStore } from '@stores/appUserSessionStore';
import { useLayoutSizeStore } from '@stores/layoutSizeStore';
import { useNotificationStore } from '@stores/notificationStore';
import { useQuery } from '@tanstack/react-query';
import { getNativeAppInfoFromUserAgent } from '@utils/common';
import { APP_BASE_PATH, B2B_URL, TUTOR_URL } from '@variables';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useComponentSize } from 'react-use-size';
import './AppLayout.scss';

interface BottomButtonProps {
  selected?: boolean;
  onClick?: (event: any) => void;
  selectedIcon?: any;
  icon: any;
  label?: string;
}

const DesktopExtensionView: React.FC = () => {
  const appNavigation = useAppNavigation();
  const { linkTo } = useDeeplink();
  const [searchKeyword, setSearchKeyword] = useState<string>();

  const popularKeywordsQuery = useQuery({
    queryKey: [`feed-search-popular-keywords`],
    queryFn: () => feedApi.getSearchPopularKeywords(),
  });

  const searchPlaceholderQuery = useQuery({
    queryKey: [`feed-search-placeholders`],
    queryFn: () => feedApi.getSearchPlaceholder(),
  });

  const popularKeywords = popularKeywordsQuery.data?.data?.data?.[0].data || [];
  const searchPlaceholder = searchPlaceholderQuery.data?.data?.data?.[0].placeholder;
  const search = () => {
    if (searchKeyword && searchKeyword.trim().length > 0) {
      linkTo(
        { action: DeepLinkActions.SEARCH_KEYWORD, payload: { keyword: searchKeyword?.trim() } },
        { source: 'desktop-extension-view' },
      );
      setSearchKeyword('');
    }
  };

  return (
    <OverlayScrollbarsComponent
      defer
      options={{
        scrollbars: { autoHide: 'scroll' },
        overflow: {
          x: 'hidden',
        },
      }}
      style={{
        backgroundColor: 'transparent',
        position: 'relative',
        overflow: 'auto',
        maxHeight: '560px',
        height: '100%',
        width: '310px',
        paddingTop: '20px',
        paddingBottom: '20px',
      }}>
      <div style={{ width: '310px', height: '518px' }}>
        <button
          className="link-button"
          style={{ display: 'flex', alignItems: 'flex-end' }}
          onClick={() => {
            appNavigation.to(`${APP_BASE_PATH}`);
          }}>
          <img src={DesktopExtensionTitle} alt=""></img>
          <div
            style={{
              marginLeft: '6px',
              height: '22px',
              color: 'var(--Neutral-100, #242424)',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
            }}>
            아이를 위한 모든 경험
          </div>
        </button>
        <div
          style={{
            marginTop: '24px',
            height: '56px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: '2px solid #FF3D8F',
            borderRadius: '28px',
          }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '24px',
              width: '100%',
              height: '100%',
              color: 'var(--Neutral-90, #424242)',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
            }}>
            <input
              placeholder={searchPlaceholder}
              style={{
                marginTop: 0,
                border: 'none',
                outline: 'none',
                backgroundColor: 'transparent',
                fontSize: '15px',
                width: '100%',
              }}
              value={searchKeyword}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter') {
                  search();
                }
              }}></input>
          </div>
          <button
            className="link-button"
            style={{
              marginRight: '4px',
              backgroundColor: '#FF3D8F',
              borderRadius: '50%',
              width: '44px',
              height: '44px',
              flexShrink: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => {
              search();
            }}>
            <DesktopExtensionSearchIcon></DesktopExtensionSearchIcon>
          </button>
        </div>
        <div
          style={{ marginTop: '24px', display: 'flex', flexWrap: 'wrap', gap: '6px 4px', width: '100%', flexGrow: 0 }}>
          {popularKeywords.slice(0, 6).map((keywordItem) => {
            const keyword = keywordItem._id;
            return (
              <button
                key={keyword}
                className="link-button"
                style={{
                  display: 'flex',
                  height: '32px',
                  padding: '0 14px',
                  borderRadius: '16px',
                  border: '1px solid #E8EAED',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'var(--Neutral-90, #424242)',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                }}
                onClick={() => {
                  linkTo(
                    { action: DeepLinkActions.SEARCH_KEYWORD, payload: { keyword: keyword } },
                    { source: 'desktop-extension-view-popular-keywords' },
                  );
                }}>
                {keyword}
              </button>
            );
          })}
        </div>
        <div
          style={{
            marginTop: '72px',
            color: 'var(--Neutral-100, #242424)',
            fontFamily: 'Pretendard',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
          }}>
          앱에서 2천원 할인받기!
        </div>
        <div style={{ marginTop: '12px', display: 'flex', gap: '8px' }}>
          <button
            className="link-button"
            onClick={() => {
              window.open('https://apps.apple.com/us/app/id1524244020', '_blank');
            }}>
            <DesktopExtensionAppStoreIcon></DesktopExtensionAppStoreIcon>
          </button>
          <button
            className="link-button"
            onClick={() => {
              window.open('https://play.google.com/store/apps/details?id=com.igogo', '_blank');
            }}>
            <DesktopExtensionPlayStoreIcon></DesktopExtensionPlayStoreIcon>
          </button>
        </div>
        <div style={{ marginTop: '72px', display: 'flex', gap: '12px' }}>
          <button
            className="link-button"
            onClick={() => {
              window.open('https://www.instagram.com/igogo_class', '_blank');
            }}>
            <div
              style={{
                width: '36px',
                height: '36px',
                borderRadius: '18px',
                backgroundColor: '#B9BBBE',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <DesktopExtensionInstagramIcon></DesktopExtensionInstagramIcon>
            </div>
          </button>
          <button
            className="link-button"
            onClick={() => {
              window.open('http://blog.naver.com/myigo', '_blank');
            }}>
            <div
              style={{
                width: '36px',
                height: '36px',
                borderRadius: '18px',
                backgroundColor: '#B9BBBE',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <DesktopExtensionNaverBlogIcon></DesktopExtensionNaverBlogIcon>
            </div>
          </button>
        </div>
        <div style={{ marginTop: '20px', display: 'flex', gap: '8px' }}>
          <button
            className="link-button"
            style={{
              height: '36px',
              borderRadius: '6px',
              color: 'white',
              padding: '0 12px',
              backgroundColor: '#3563FF',
            }}
            onClick={() => {
              window.open(TUTOR_URL, '_blank');
            }}>
            선생님인가요?
          </button>
          <button
            className="link-button"
            style={{
              height: '36px',
              borderRadius: '6px',
              border: '1px solid #E8EAED',
              padding: '0 12px',
              color: 'var(--Neutral-90, #424242)',
              textAlign: 'center',
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
            }}
            onClick={() => {
              window.open(B2B_URL, '_blank');
            }}>
            기업/기관문의
          </button>
          <button
            className="link-button"
            style={{
              height: '36px',
              borderRadius: '6px',
              border: '1px solid #E8EAED',
              padding: '0 12px',
              color: 'var(--Neutral-90, #424242)',
              textAlign: 'center',
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
            }}
            onClick={() => {
              window.open('https://igogo.notion.site/6748c9d3fa3c43a4bd2086b7c60da481', '_blank');
            }}>
            제휴문의
          </button>
        </div>
      </div>
    </OverlayScrollbarsComponent>
  );
};

const BottomButton: React.FC<BottomButtonProps> = (props) => {
  return (
    <button
      className="link-button"
      style={{
        cursor: 'pointer',
        width: '52px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'cetner',
        justifyContent: 'center',
        gap: '4px',
      }}
      onClick={props.onClick}>
      {props.selected ? props.selectedIcon || props.icon : props.icon}
      <div
        style={{
          color: props.selected ? '#242424' : '#808387',
          fontSize: '11px',
          alignSelf: 'center',
        }}>
        {props.label}
      </div>
    </button>
  );
};
export const Header: React.FC<{
  children?: any;
  height?: string;
  hide?: boolean;
  style?: any;
  title?: any;
  leftButton?: any;
  rightButton?: any;
}> = (props) => {
  const appNavigation = useAppNavigation();
  return (
    <div
      className="layout-header"
      style={{
        visibility: props.hide ? 'hidden' : 'visible',
        height: props.hide ? 0 : props.height || HEADER_HEIGHT,
        display: 'flex',
        justifyContent: 'space-between',
        flexShrink: 0,
        width: '100%',
        ...props.style,
      }}>
      {props.children ? (
        props.children
      ) : (
        <div
          style={{
            display: 'flex',
            height: HEADER_HEIGHT,
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '56px',
              height: HEADER_HEIGHT,
            }}>
            {props.leftButton ? (
              props.leftButton
            ) : (
              <button
                className="link-button"
                style={{ height: HEADER_HEIGHT }}
                onClick={() => {
                  appNavigation.back();
                }}>
                <NavBackIcon color={'#242424'} />
              </button>
            )}
          </div>
          <div className="app-header-title">{props.title}</div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: HEADER_HEIGHT,
              minWidth: '56px',
            }}>
            {props.rightButton}
          </div>
        </div>
      )}
    </div>
  );
};

export const Contents: React.FC<{ children?: any; style?: any; className?: string }> = (props) => {
  const { ref, height, width } = useComponentSize();
  const layoutSizeStore = useLayoutSizeStore();

  useEffect(() => {
    layoutSizeStore.setLayoutSize({ width, height });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  return (
    <div
      className={`layout-contents ${props.className}`}
      ref={ref}
      style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', position: 'relative', ...props.style }}>
      {props.children}
    </div>
  );
};

export const Footer: React.FC<{ children?: any; height?: string; hide?: boolean; onScollToTop?: () => void }> = (
  props,
) => {
  const location = useLocation();
  const appNavigation = useAppNavigation();
  const sessionStore = useAppUserSessionStore();

  const hideNewBubble = () => {
    sessionStore.setShowLoungeNewBubble(false);
  };
  useEffect(() => {
    window.addEventListener('scroll', hideNewBubble);
    window.addEventListener('click', hideNewBubble);
    return () => {
      window.removeEventListener('scroll', hideNewBubble);
      window.removeEventListener('click', hideNewBubble);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.hide) {
    return <div className="layout-footer"></div>;
  }
  return (
    <div
      className="layout-footer"
      style={{
        height: props.height || '56px',
        display: 'flex',
        alignItems: 'flex-start',
        flexShrink: 0,
        borderTop: '1px solid #e8e8e8',
      }}>
      <div style={{ height: '56px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
            alignSelf: 'center',
            position: 'relative',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto',
              left: 0,
              right: 0,
              bottom: '40px',
              transition: 'all 0.5s',
              opacity: sessionStore.showLoungeNewBubble ? 1 : 0,
              zIndex: sessionStore.showLoungeNewBubble ? 1001 : -1,
            }}>
            <div
              style={{
                color: 'var(--White-100, #FFF)',
                fontFamily: 'Pretendard',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 500,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '8px 12px',
                borderRadius: '4px',
                background: 'var(--Primary-95, #FF3D8F)',
              }}>
              새로 나왔어요!
            </div>
            <BubbleTail />
          </div>
          <BottomButton
            label="홈"
            selectedIcon={<FooterHomeOn style={{ width: '24px', height: '24px', alignSelf: 'center' }} />}
            icon={<FooterHomeOff style={{ width: '24px', height: '24px', alignSelf: 'center' }} />}
            selected={location.pathname === `${APP_BASE_PATH}`}
            onClick={() => {
              appNavigation.to(`${APP_BASE_PATH}`);
              if (location.pathname === `${APP_BASE_PATH}`) {
                props.onScollToTop && props.onScollToTop();
              }
            }}
          />

          <BottomButton
            label="맞춤 검색"
            selectedIcon={<FooterSearchOn style={{ width: '24px', height: '24px', alignSelf: 'center' }} />}
            icon={<FooterSearchOff style={{ width: '24px', height: '24px', alignSelf: 'center' }} />}
            selected={location.pathname === `${APP_BASE_PATH}search`}
            onClick={() => {
              appNavigation.to(`${APP_BASE_PATH}search`);
              if (location.pathname === `${APP_BASE_PATH}search`) {
                props.onScollToTop && props.onScollToTop();
              }
            }}
          />

          <BottomButton
            label="라운지"
            selectedIcon={<FooterLoungeOn style={{ width: '24px', height: '24px', alignSelf: 'center' }} />}
            icon={<FooterLoungeOff style={{ width: '24px', height: '24px', alignSelf: 'center' }} />}
            selected={location.pathname === `${APP_BASE_PATH}lounge`}
            onClick={() => {
              appNavigation.to(`${APP_BASE_PATH}lounge`);
              if (location.pathname === `${APP_BASE_PATH}lounge`) {
                props.onScollToTop && props.onScollToTop();
              }
            }}
          />

          <BottomButton
            label="채팅"
            selectedIcon={<FooterChatOn style={{ width: '24px', height: '24px', alignSelf: 'center' }} />}
            icon={<FooterChatOff style={{ width: '24px', height: '24px', alignSelf: 'center' }} />}
            selected={location.pathname === `${APP_BASE_PATH}chats`}
            onClick={() => {
              appNavigation.to(`${APP_BASE_PATH}chats`);
              if (location.pathname === `${APP_BASE_PATH}chats`) {
                props.onScollToTop && props.onScollToTop();
              }
            }}
          />

          <BottomButton
            label="마이페이지"
            selectedIcon={<FooterMyPageOn style={{ width: '24px', height: '24px', alignSelf: 'center' }} />}
            icon={<FooterMyPageOff style={{ width: '24px', height: '24px', alignSelf: 'center' }} />}
            selected={location.pathname === `${APP_BASE_PATH}my-page`}
            onClick={() => {
              appNavigation.to(`${APP_BASE_PATH}my-page`);
              if (location.pathname === `${APP_BASE_PATH}my-page`) {
                props.onScollToTop && props.onScollToTop();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const Layout: React.FC<{ children?: any }> = (props) => {
  const appNavigation = useAppNavigation();
  useAppMessage();
  useAppPopup();
  useAppVersion();
  const notificationStore = useNotificationStore();
  const { linkTo } = useDeeplink();
  const [windowSize, setWindowSize] = React.useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const isMobile = windowSize.width < 780;

  const { isNativeApp } = getNativeAppInfoFromUserAgent();
  const hideDesktopExtension = isMobile || isNativeApp;

  if (window.history.scrollRestoration) {
    window.history.scrollRestoration = 'manual';
  }

  window.onpopstate = (event) => {
    const savedStateIdx = appNavigation.store.historyState?.idx;
    const newStateIdx = event.state.idx;

    let stateDirection = 'back';
    if (savedStateIdx && savedStateIdx < newStateIdx) {
      stateDirection = 'forward';
    }

    if (stateDirection === 'back') {
      appNavigation.back(false);
    }
  };

  const updateSize = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    if (notificationStore.notifications.length > 0) {
      const newNotifications = [...notificationStore.notifications];
      while (newNotifications.length > 0) {
        const notification = newNotifications.pop();
        toast.info(`[${notification?.title}] ${notification?.description}`, {
          onClick: () => {
            if (notification?.action) {
              linkTo(notification?.action, { source: 'notification-toast' });
            }
          },
        });
      }
      notificationStore.clearNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationStore.notifications]);

  return (
    <div
      id="igogo-app-layout"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100vw',
        height: '100dvh',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
      }}>
      {!hideDesktopExtension && (
        <DesktopBackground
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100vw',
            height: '100dvh',
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden',
            objectFit: 'cover',
          }}
        />
      )}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        style={{
          height: '100dvh',
          display: 'flex',
          alignItems: 'center',
          position: hideDesktopExtension ? 'fixed' : 'relative',
          left: hideDesktopExtension ? 0 : undefined,
          top: hideDesktopExtension ? 0 : undefined,
          width: hideDesktopExtension ? '100%' : '920px',
        }}>
        {!isMobile && <div style={{ width: '24px' }}></div>}
        {!isMobile && <DesktopExtensionView></DesktopExtensionView>}
        {!isMobile && <div style={{ maxWidth: '120px', width: '100%', minWidth: '24px', flex: 1 }}></div>}
        <div
          style={{
            height: '100dvh',
            width: hideDesktopExtension ? '100%' : '480px',
            border: 'none',
            backgroundColor: 'white',
            borderRadius: 0,
            overflow: 'hidden',
            transform: 'translate(0px, 0px)',
            alignSelf: 'center',
            boxShadow: '0 4px 23px 0 rgba(0,0,0,.09)',
            boxSizing: hideDesktopExtension ? 'border-box' : 'content-box',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <AppLoginModal />
          <ClassInvoiceModal />
          <OkCancelDialog />
          <AppBottomSheet />
          <AppTermsModal />
          <AppUpdateModal />
          <LoungeEditorModal />
          {props.children}
        </div>
      </div>
    </div>
  );
};
