import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useState } from 'react';

export const LinkPreviewComponent = ({ node }: NodeViewProps) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  let linkDomain = undefined;
  try {
    const { hostname } = new URL(node.attrs.link);
    linkDomain = hostname;
  } catch {
    linkDomain = node.attrs.link;
  }

  return (
    <NodeViewWrapper className="lounge-editor-link-preview" data-link={node.attrs.link} contentEditable={false}>
      <div
        contentEditable={false}
        style={{
          width: '100%',
          border: '1px solid var(--Neutral-20, #eff1f4)',
          background: 'var(--White-100, #fff)',
          display: 'flex',
          height: '76px',
          alignItems: 'center',
          cursor: 'pointer',
          overflow: 'hidden',
        }}
        onClick={() => {
          if (node.attrs.link) {
            window.open(node.attrs.link, '_blank');
          }
        }}>
        <img
          style={{ display: 'none' }}
          src={node.attrs.imageUrl}
          onLoad={() => {
            setImageLoaded(true);
          }}
          alt="loading"
        />
        {node.attrs.imageUrl && node.attrs.imageUrl !== 'undefined' && imageLoaded && (
          <img
            src={node.attrs.imageUrl}
            alt=""
            style={{ display: 'block', width: '76px', height: '76px', objectFit: 'cover' }}
          />
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            flex: '1 1 auto',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordBreak: 'break-word',
            whiteSpace: 'nowrap',
            marginLeft: '12px',
            marginRight: '12px',
          }}>
          {node.attrs.title && node.attrs.title !== 'undefined' && (
            <div
              contentEditable={false}
              style={{
                color: 'var(--Neutral-90, #424242)',
                fontFamily: 'Pretendard',
                fontSize: '13px',
                fontStyle: 'normal',
                fontWeight: 400,
              }}>
              {node.attrs.title}
            </div>
          )}
          {node.attrs.description && node.attrs.description !== 'undefined' && (
            <div
              contentEditable={false}
              style={{
                width: 'calc(100% - 76px -20px)',
                color: 'var(--Neutral-60, #9b9da0)',
                fontFamily: 'Pretendard',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 500,
              }}>
              {node.attrs.description}
            </div>
          )}
          <div
            contentEditable={false}
            style={{
              width: 'calc(100% - 76px - 20px)',
              color: 'var(--Blue-60, #1890ff)',
              whiteSpace: 'nowrap',
              fontFamily: 'Pretendard',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
            }}>
            {linkDomain}
          </div>
        </div>
      </div>
    </NodeViewWrapper>
  );
};

export default LinkPreviewComponent;
