import { ClassInvoiceCountByState, GetClassInvoiceQuery, LegacyClassInvoice } from '@models/invoice';
import { UserPointLog } from '@models/logs';
import { ProductModel } from '@models/product';
import { NotificationItem } from '@models/notification';
import {
  ClassPurchaseModel,
  CommercePurchaseModel,
  PaymentStatement,
  RefundRequestBody,
  RefundCancelBody,
} from '@models/purchase';
import { apiClient } from '.';
import { User } from '../models/auth';
import { PagenatedResponse } from '@models/common';
import { UserContent, UserContentContainer } from '@models/user-contents';

export const getUser = async (userId: string) => {
  return apiClient.get<User>(`/app/v1/user/${userId}`);
};

export const getFavorite = async (type: string = 'product') => {
  return apiClient.get<ProductModel[] | UserContent[] | User[]>(`/app/v1/user/me/favorite`, { params: { type } });
};

export const getRecommendProducts = async () => {
  return apiClient.get<ProductModel[]>(`/app/v1/user/me/recommend-products`);
};

export const getFavorites = async (query: { page?: number; size?: number; type?: string }) => {
  return apiClient.get<PagenatedResponse<ProductModel>>(`/app/v1/user/me/favorites`, { params: query });
};

export const getPurchases = async (page: number, size: number) => {
  return apiClient.get<PagenatedResponse<PaymentStatement>>(`/app/v1/user/me/purchases`, { params: { page, size } });
};

export const getReviewablePurchases = async () => {
  return apiClient.get<PaymentStatement[]>(`/app/v1/user/me/purchases/reviewable`);
};

export const getClassProductPurchases = async () => {
  return apiClient.get<ClassPurchaseModel[]>(`/app/v1/user/me/purchases/class-product`);
};

export const getCommerceProductPurchases = async () => {
  return apiClient.get<CommercePurchaseModel[]>(`/app/v1/user/me/purchases/commerce-product`);
};

export const getMyClassInvoices = async (query: GetClassInvoiceQuery) => {
  return apiClient.get<{
    result: LegacyClassInvoice[];
    count: { [key: string]: ClassInvoiceCountByState };
  }>(`/app/v1/user/me/class-invoices`, {
    params: query,
  });
};

export const useCoupon = async (couponCode: string) => {
  return apiClient.post<{ point: number; eventName: string }>(`/app/v1/user/me/coupon`, { couponCode });
};

export const getMyPointLogs = async () => {
  return apiClient.get<UserPointLog[]>(`/app/v1/user/me/point`);
};

export const getMyRewards = async () => {
  return apiClient.get<{ count: number; points: number }>(`/app/v1/user/me/reward`);
};

export const getMyNotifications = async (searchQuery: { page: number; size: number }) => {
  return apiClient.get<PagenatedResponse<NotificationItem>>(`/app/v1/user/me/notifications`, {
    params: searchQuery,
  });
};

export const createCustomerRequest = async (body: any) => {
  return apiClient.post(`/app/v1/customer-request`, body);
};

export const getSellerReviewsWithPagenate = async (sellerId: string, page?: number, size?: number) => {
  return apiClient.get<UserContentContainer>(`/app/v1/partners/${sellerId}/reviews`, {
    params: { page, size },
  });
};

export const changeRefundStatus = async (data: {
  paymentStatementId: string;
  body: RefundRequestBody | RefundCancelBody;
}) => {
  return apiClient.patch<PaymentStatement>(`/app/v1/payment-statement/${data.paymentStatementId}/refund`, data.body);
};
