import { ReactComponent as StarOff } from '@assets/images/app/star-gray.svg';
import { ReactComponent as StarOn } from '@assets/images/app/star-pink.svg';
import React from 'react';

export interface StarsProps {
  size?: number;
  count?: number;
  value?: number;
  onChange?: (value: number) => void;
}

export const Stars: React.FC<StarsProps> = (props) => {
  const value = props.value || 5;
  const total = props.count || 5;
  return (
    <div style={{ display: 'flex' }}>
      {[...new Array(total)].map((_, index) => {
        return (
          <button
            className="link-button"
            onClick={() => {
              props.onChange?.(index + 1);
            }}>
            {index < value ? <StarOn /> : <StarOff />}
          </button>
        );
      })}
    </div>
  );
};
