import HeaderCloseIcon from '@assets/images/app/icon-navi-close.png';
import React from 'react';
import { NavBackIcon } from './Icons';

export interface AppBottomModalPros {
  onLeftNaviButtonClick?: (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => void;
  onRightNaviButtonClick?: (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => void;
  title?: any;
  titleStyle?: any;
  headerStyle?: any;
  children?: any;
  footer?: any;
  open?: boolean;
  height?: number;
  leftNaviButtonIcon?: 'back' | 'close';
  hideLeftNaviButton?: boolean;
  hideRightNaviButton?: boolean;
  hideHeader?: boolean;
  rightNaviButton?: any;
}

export const AppBottomModal: React.FC<AppBottomModalPros> = (props) => {
  const { open } = props;
  const height = props.height ? `${props.height}px` : '100dvh';

  return (
    <div
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (height && !['100dvh', '100%', '100vh'].includes(height)) {
          props.onRightNaviButtonClick && props.onRightNaviButtonClick(e);
        }
      }}
      style={{
        transition: 'opacity 0.3s',
        opacity: open ? 1 : 0,
        position: 'absolute',
        width: open ? '100%' : 0,
        height: '100%',
        maxHeight: '100dvh',
        backgroundColor: 'rgba(0,0,0,0.4)',
        zIndex: 99999,
        overflow: 'hidden',
      }}>
      <div
        style={{
          // transition: 'all 0.3s',
          position: 'absolute',
          opacity: open ? 1 : 0,
          bottom: open ? 0 : `-${height}`,
          height: `${height}`,
          width: '100%',
          backgroundColor: 'white',
          borderRadius: height === '100dvh' ? 'none' : '8px 8px 0 0',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          ...props.headerStyle,
        }}>
        {!props.hideHeader && (
          <div
            id="bottom-modal-header"
            style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '52px', flexShrink: 0 }}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <div style={{ width: '80px' }}>
                <div style={{ minWidth: '52px' }}>
                  {!props.hideLeftNaviButton && (
                    <button
                      className="link-button"
                      style={{ padding: '12px', cursor: 'pointer' }}
                      onClick={(e) => {
                        props.onLeftNaviButtonClick && props.onLeftNaviButtonClick(e);
                      }}>
                      {props.leftNaviButtonIcon === 'close' ? (
                        <img style={{ width: '24px', height: '24px' }} src={HeaderCloseIcon} alt="" />
                      ) : (
                        <NavBackIcon color={'#242424'} />
                      )}
                    </button>
                  )}
                </div>
              </div>
              <div
                style={{
                  alignSelf: 'center',
                  color: 'var(--Neutral-100, #242424)',
                  textAlign: 'center',
                  fontFamily: 'Pretendard',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  ...props.titleStyle,
                }}>
                {props.title}
              </div>
              <div style={{ width: '80px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <div style={{ minWidth: '52px' }}>
                  {!props.hideRightNaviButton && (
                    <button
                      className="link-button"
                      style={{ padding: '12px', cursor: 'pointer' }}
                      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        props.onRightNaviButtonClick && props.onRightNaviButtonClick(e);
                      }}>
                      {props.rightNaviButton ? (
                        props.rightNaviButton
                      ) : (
                        <img style={{ width: '24px', height: '24px' }} src={HeaderCloseIcon} alt="" />
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          id="bottom-modal-body"
          style={{ height: `calc(100% - ${props.hideHeader ? 0 : 52}px - ${props.footer ? 60 : 0}px)` }}>
          {props.children}
        </div>
        {props.footer && (
          <div id="bottom-modal-footer" style={{ alignSelf: 'flex-end', width: '100%', height: '60px', flexShrink: 0 }}>
            {props.footer}
          </div>
        )}
      </div>
    </div>
  );
};

export default AppBottomModal;
