export const DELIMITER = '\u0001';
// eslint-disable-next-line no-useless-escape
export const REGEX_URL =
  /\b(https?|ftp|file):\/\/[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
// eslint-disable-next-line no-useless-escape
export const REGEX_BOLD = /(\".*?(|\")\")/g;
// eslint-disable-next-line no-useless-escape
export const REGEX_PHONE_NUMBER = /(^\d{2,3}-\d{3,4}-\d{4}$|^\d{9,11}$)/g;
export const REGEX_EMAIL = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
export const URL_PATTERN =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-zA-Z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
