import { UserMembership } from '@models/common';
import React, { CSSProperties } from 'react';

export const MembershipBadge: React.FC<{
  membership: Partial<UserMembership>;
  showBenefitSummary?: boolean;
  style?: CSSProperties;
}> = (props) => {
  const membership = props.membership;

  return (
    <div
      style={{
        color: 'var(--Blue-60, #1890FF)',
        fontFamily: 'Pretendard',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 500,
        ...props.style,
      }}>
      {membership.hideDiscountPercent
        ? `${membership.name} 혜택가 적용중`
        : `${membership.name} ${membership.discountPercent}% 추가할인 적용중`}
    </div>
  );
};

export default MembershipBadge;
