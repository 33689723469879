import moment from 'moment';

export interface ConvertDateTimeOptions {
  timeToZero?: boolean;
}

export const localTimeToUtcISOString = (datetime: Date | string | null, options?: ConvertDateTimeOptions) => {
  if (!datetime) {
    return null;
  }

  if (options?.timeToZero) {
    return moment(datetime).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).utc().toISOString();
  }

  return moment(datetime).utc().toISOString();
};

export const utcTimcToLocalISOString = (datetime: Date | string | null, options?: ConvertDateTimeOptions) => {
  if (!datetime) {
    return null;
  }

  if (options?.timeToZero) {
    return moment.utc(datetime).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).local().toISOString();
  }

  return moment.utc(datetime).local().toISOString();
};

export const getDayStringArrayFromDates = (startDate: Date, endDate: Date) => {
  const result: string[] = [];

  let cursorDate = startDate;
  while (cursorDate < endDate) {
    result.push(moment(cursorDate).format('MM/DD'));
    cursorDate = moment(cursorDate).add(1, 'days').toDate();
  }
  result.push(moment(endDate).format('MM/DD'));
  return result;
};

export const getMonthStringArrayFromDates = (startDate: Date, endDate: Date) => {
  const result: string[] = [];

  let cursorDate = startDate;
  while (cursorDate < endDate) {
    result.push(moment(cursorDate).format('YYYY-MM'));
    cursorDate = moment(cursorDate).add(1, 'months').toDate();
  }
  result.push(moment(endDate).format('YYYY-MM'));
  return result;
};

export const checkIsDateType = (date: Date) => {
  return !isNaN(date.getTime());
};
