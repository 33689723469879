import * as partnerApi from '@apis/app-partners';
import * as productApi from '@apis/app-products';
import * as userApi from '@apis/app-user';
import * as userContentApi from '@apis/app-user-contents';
import { User } from '@models/auth';
import { UserContent } from '@models/user-contents';
import { useAuthStore } from '@stores/authStore';
import { useAppLoginModal } from '@stores/loginModalStore';
import { useLoungeContentsStore } from '@stores/loungeContentsStore';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

export const useUserFavorites = () => {
  const [favoritesProducts, setFavoritesProducts] = useState<{ [key: string]: any }>({});
  const [favoritesPartners, setFavoritesPartners] = useState<{ [key: string]: any }>({});
  const [favoritesContents, setFavoritesContents] = useState<{ [key: string]: any }>({});
  const loginModal = useAppLoginModal();
  const authStore = useAuthStore();
  const queryClient = useQueryClient();
  const loungeStore = useLoungeContentsStore();

  const mutationAddFavorite = useMutation({
    mutationFn: productApi.createMyFavorites,
    onSuccess: async () => {
      const response = await userApi.getFavorite('product');
      const favoriteProductIds = response.data.map((item) => {
        return item && item._id;
      });
      authStore.setFavorites(favoriteProductIds as string[]);
    },
    onError: (e: any) => {
      if (e?.response?.status === 401) {
        authStore.setUser(undefined);
        authStore.setFavorites(undefined);
        authStore.setFavoritesPartners(undefined);
        authStore.setFavoritesUserContents(undefined);
        loginModal.open();
      }
    },
  });

  const mutationRemoveFavorite = useMutation({
    mutationFn: productApi.deleteMyFavorites,
    onSuccess: async () => {
      const response = await userApi.getFavorite();
      const favoriteProductIds = response.data.map((item) => {
        return item && item._id;
      });
      authStore.setFavorites(favoriteProductIds as string[]);
    },
    onError: (e: any) => {
      if (e?.response?.status === 401) {
        authStore.setUser(undefined);
        authStore.setFavorites(undefined);
        authStore.setFavoritesPartners(undefined);
        authStore.setFavoritesUserContents(undefined);
        loginModal.open();
      }
    },
  });
  const mutationAddPartnerFavorite = useMutation({
    mutationFn: partnerApi.createMyFavorites,
    onSuccess: async () => {
      const response = await userApi.getFavorite('partner');
      const favoritePartnerIds = (response.data as User[]).map((item) => {
        return item && item._id;
      });
      authStore.setFavoritesPartners(favoritePartnerIds as string[]);
    },
    onError: (e: any) => {
      if (e?.response?.status === 401) {
        authStore.setUser(undefined);
        authStore.setFavorites(undefined);
        authStore.setFavoritesPartners(undefined);
        authStore.setFavoritesUserContents(undefined);
        loginModal.open();
      }
    },
  });

  const mutationRemovePartnerFavorite = useMutation({
    mutationFn: partnerApi.deleteMyFavorites,
    onSuccess: async () => {
      const response = await userApi.getFavorite('partner');
      const favoritePartnerIds = (response.data as User[]).map((item) => {
        return item && item._id;
      });
      authStore.setFavoritesPartners(favoritePartnerIds as string[]);
    },
    onError: (e: any) => {
      if (e?.response?.status === 401) {
        authStore.setUser(undefined);
        authStore.setFavorites(undefined);
        authStore.setFavoritesPartners(undefined);
        authStore.setFavoritesUserContents(undefined);
        loginModal.open();
      }
    },
  });

  const mutationAddUserContentFavorite = useMutation({
    mutationFn: userContentApi.createMyFavorites,
    onSuccess: async (data, variables) => {
      const response = await userApi.getFavorite('user-content');
      const favoriteUserContentIds = (response.data as UserContent[]).map((item) => {
        return item && item._id;
      });
      authStore.setFavoritesUserContents(favoriteUserContentIds);
      queryClient.invalidateQueries({ queryKey: [`lounge-feed-item`, variables] });
      loungeStore.increaseFavoriteCount(variables);
    },
    onError: (e: any) => {
      if (e?.response?.status === 401) {
        authStore.setUser(undefined);
        authStore.setFavorites(undefined);
        authStore.setFavoritesPartners(undefined);
        authStore.setFavoritesUserContents(undefined);
        loginModal.open();
      }
    },
  });

  const mutationRemoveUserContentFavorite = useMutation({
    mutationFn: userContentApi.deleteMyFavorites,
    onSuccess: async (data, variables) => {
      const response = await userApi.getFavorite('user-content');
      const favoriteUserContentIds = (response.data as UserContent[]).map((item) => {
        return item && item._id;
      });

      authStore.setFavoritesUserContents(favoriteUserContentIds);
      queryClient.invalidateQueries({ queryKey: [`lounge-feed-item`, variables] });
      loungeStore.decreaseFavoriteCount(variables);
    },
    onError: (e: any) => {
      if (e?.response?.status === 401) {
        authStore.setUser(undefined);
        authStore.setFavorites(undefined);
        authStore.setFavoritesPartners(undefined);
        authStore.setFavoritesUserContents(undefined);
        loginModal.open();
      }
    },
  });

  const updateUserFavorites = async () => {
    const favorites = (await userApi.getFavorite('product'))?.data || [];
    const favoriteIds = favorites.map((item) => {
      return item && item._id;
    });
    authStore.setFavorites(favoriteIds as string[]);

    const favoritesPartners = (await userApi.getFavorite('partner'))?.data || [];
    const favoriteUserIds = (favoritesPartners as User[]).map((item) => {
      return item && item._id;
    });
    authStore.setFavoritesPartners(favoriteUserIds as string[]);

    const favoritesUserContents = (await userApi.getFavorite('user-content'))?.data || [];
    const favoriteContentIds = favoritesUserContents.map((item) => {
      return item && item._id;
    });
    authStore.setFavoritesUserContents(favoriteContentIds as string[]);
  };

  useEffect(() => {
    const dict: { [key: string]: any } = {};
    (authStore.favorites || []).forEach((productId: string) => {
      dict[productId] = 1;
    });
    setFavoritesProducts(dict);
  }, [authStore.favorites]);

  const isFavoriteProduct = (productId: string): boolean => {
    return !!favoritesProducts[productId];
  };

  useEffect(() => {
    const dict: { [key: string]: any } = {};
    (authStore.favoritesPartners || []).forEach((partnerId: string) => {
      dict[partnerId] = 1;
    });
    setFavoritesPartners(dict);
  }, [authStore.favoritesPartners]);

  const isFavoritePartner = (partnerId: string): boolean => {
    return !!favoritesPartners[partnerId];
  };

  useEffect(() => {
    const dict: { [key: string]: any } = {};
    (authStore.favoritesUserContents || []).forEach((userContentId: string) => {
      dict[userContentId] = 1;
    });
    setFavoritesContents(dict);
  }, [authStore.favoritesUserContents]);

  const isFavoriteContent = (userContentId: string): boolean => {
    return !!favoritesContents[userContentId];
  };

  return {
    addProductToFavorites: mutationAddFavorite.mutate,
    removeProductFromFavorites: mutationRemoveFavorite.mutate,
    addPartnerToFavorites: mutationAddPartnerFavorite.mutate,
    removePartnerFromFavorites: mutationRemovePartnerFavorite.mutate,
    addContentToFavorites: mutationAddUserContentFavorite.mutate,
    removeContentFromFavorites: mutationRemoveUserContentFavorite.mutate,
    favoritesProducts: favoritesProducts,
    favoritesPartners: favoritesPartners,
    favoritesContents: favoritesContents,
    isFavoriteProduct,
    isFavoritePartner,
    isFavoriteContent,
    updateUserFavorites,
  };
};
