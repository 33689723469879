import React, { CSSProperties, useEffect, useRef, useState } from 'react';

export const SvgInline: React.FC<{
  url: string;
  style?: CSSProperties;
  onLoad?: (size: { width: number; height: number }) => void;
}> = (props) => {
  const ref = useRef<any>();
  const [svg, setSvg] = useState<string>('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErrored, setIsErrored] = useState(false);

  useEffect(() => {
    fetch(props.url)
      .then((res) => res.text())
      .then(setSvg)
      .catch(setIsErrored)
      .then(() => setIsLoaded(true));
  }, [props.url]);

  useEffect(() => {
    if (isLoaded && ref.current) {
      props.onLoad?.({ width: ref.current.offsetWidth, height: ref.current.offsetHeight });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  return (
    <div
      ref={ref}
      style={props.style}
      className={`svgInline svgInline--${isLoaded ? 'loaded' : 'loading'} ${isErrored ? 'svgInline--errored' : ''}`}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  );
};
export default SvgInline;
